var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-4"
  }, [_c('h6', [_vm._v("Products")]), _vm._m(0), _c('el-divider'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Search Items..."
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.searchHandler.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searching,
      callback: function ($$v) {
        _vm.searching = $$v;
      },
      expression: "searching"
    }
  }, [_vm.searching ? _c('el-button', {
    attrs: {
      "slot": "append",
      "icon": "el-icon-close"
    },
    on: {
      "click": _vm.clearSearch
    },
    slot: "append"
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "col-md-6 text-right"
  }, [_c('b-button', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'rft-product-edit',
      expression: "'rft-product-edit'"
    }],
    staticClass: "mr-3 float-right",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.handleProductEdit();
      }
    }
  }, [_vm._v(" + Add New Item ")])], 1)]), _c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    attrs: {
      "height": "550",
      "data": _vm.data
    },
    on: {
      "sort-change": _vm.SetSorting
    }
  }, [_vm._l(_vm.columns, function (column, index) {
    return _c('el-table-column', {
      key: index,
      attrs: {
        "sortable": _vm.columns[index].sortable,
        "min-width": _vm.columns[index].width,
        "label": _vm.columns[index] ? _vm.columns[index].label : column,
        "align": column.align,
        "prop": index,
        "formatter": typeof _vm.columns[index].formatter === 'function' ? _vm.columns[index].formatter : null
      }
    }, [_c('template', {
      slot: "header"
    }, [_vm._v(_vm._s(column.label) + " "), column.tip ? _c('el-tooltip', {
      attrs: {
        "content": column.tip,
        "placement": "top"
      }
    }, [_c('i', {
      staticClass: "el-tooltip el-icon-info"
    })]) : _vm._e()], 1)], 2);
  }), _c('el-table-column', {
    attrs: {
      "label": "",
      "min-width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          directives: [{
            name: "permission",
            rawName: "v-permission",
            value: 'rft-product-view',
            expression: "'rft-product-view'"
          }],
          attrs: {
            "size": "small"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.handleProductEdit(scope.row);
            }
          }
        }, [_c('mdicon', {
          attrs: {
            "name": "pencil",
            "size": 20
          }
        })], 1), _c('el-button', {
          directives: [{
            name: "permission",
            rawName: "v-permission",
            value: 'rft-product-edit',
            expression: "'rft-product-edit'"
          }],
          attrs: {
            "size": "small"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.confirmDelete(scope.row);
            }
          }
        }, [_c('mdicon', {
          staticClass: "text-danger",
          attrs: {
            "name": "trash-can",
            "size": 20
          }
        })], 1)];
      }
    }])
  })], 2), _c('div', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "total, prev, pager, next, jumper",
      "hide-on-single-page": true,
      "current-page": _vm.currentPage,
      "page-size": _vm.pageSize,
      "total": _vm.currentTotalPage
    },
    on: {
      "current-change": _vm.SetPageNumber
    }
  })], 1), _c('div', {
    staticClass: "rft-edit-draft-product-modal"
  }, [_c('RftEditDraftProductModal', {
    ref: "rftEditDraftProductModal"
  })], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" In this section, the buyer lists all of the products in the tender. The buyer can either input all the details or copy over a previous tender and modify the details."), _c('br'), _vm._v(" Once you have completed this section, please click the save button and then click the next step button at the bottom of this page."), _c('br'), _vm._v("You can edit all sections at anytime prior to clicking the Publish button. ")]);
}]

export { render, staticRenderFns }