var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    ref: "draftOverviewForm",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "label-width": "180px"
    }
  }, [_c('h6', [_vm._v("Key Details")]), _c('p', [_vm._v(" In this section, the buyer specifies the main parameters for the tender including contact person, brief description, RFT type, start date, end date and contract period."), _c('br'), _vm._v(" Once you have completed this section, please click the save button and then click the next step button at the bottom of this page."), _c('br'), _vm._v("You can edit all sections at anytime prior to clicking the Publish button. ")]), _c('el-divider'), _c('el-form-item', {
    attrs: {
      "label": "RFT Name",
      "prop": "rft_name"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "RFT Name",
      "readonly": !_vm.creating && !_vm.editable
    },
    model: {
      value: _vm.form.rft_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rft_name", $$v);
      },
      expression: "form.rft_name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "RFT ID",
      "prop": "uuid"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "RFT ID",
      "readonly": ""
    },
    model: {
      value: _vm.form.uuid,
      callback: function ($$v) {
        _vm.$set(_vm.form, "uuid", $$v);
      },
      expression: "form.uuid"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Contact Person",
      "prop": "contact_person"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Contact Person",
      "readonly": !_vm.creating && !_vm.editable
    },
    model: {
      value: _vm.form.contact_person,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contact_person", $$v);
      },
      expression: "form.contact_person"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Contact Phone",
      "prop": "contact_phone"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Contact Phone",
      "readonly": !_vm.creating && !_vm.editable
    },
    model: {
      value: _vm.form.contact_phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contact_phone", $$v);
      },
      expression: "form.contact_phone"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Contact Email",
      "prop": "contact_email"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Contact Email",
      "readonly": !_vm.creating && !_vm.editable
    },
    model: {
      value: _vm.form.contact_email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contact_email", $$v);
      },
      expression: "form.contact_email"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Brief Description",
      "prop": "description"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "autosize": "",
      "placeholder": "Brief Description",
      "readonly": !_vm.creating && !_vm.editable
    },
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _c('el-form-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "label": "Product Category",
      "prop": "product_category_code"
    }
  }, [_c('el-select', {
    attrs: {
      "placeholder": "Select",
      "disabled": !_vm.creating && !_vm.editable
    },
    model: {
      value: _vm.form.product_category_code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "product_category_code", $$v);
      },
      expression: "form.product_category_code"
    }
  }, _vm._l(_vm.lookups.product_category_code, function (item) {
    return _c('el-option', {
      key: item.code,
      attrs: {
        "label": item.display,
        "value": item.code
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "RFT Type",
      "prop": "rft_type_code"
    }
  }, [_c('el-select', {
    attrs: {
      "placeholder": "Select",
      "disabled": !_vm.creating && !_vm.editable
    },
    model: {
      value: _vm.form.rft_type_code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rft_type_code", $$v);
      },
      expression: "form.rft_type_code"
    }
  }, _vm._l(_vm.lookups.rft_type_code, function (item) {
    return _c('el-option', {
      key: item.code,
      attrs: {
        "label": item.display,
        "value": item.code
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "RFT Start Date",
      "prop": "rft_start_on"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "format": "dd/MM/yyyy",
      "disabled": !_vm.creating && !_vm.editable
    },
    model: {
      value: _vm.form.rft_start_on,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rft_start_on", $$v);
      },
      expression: "form.rft_start_on"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "RFT End Date",
      "prop": "rft_end_on"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "format": "dd/MM/yyyy",
      "disabled": !_vm.creating && !_vm.editable
    },
    model: {
      value: _vm.form.rft_end_on,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rft_end_on", $$v);
      },
      expression: "form.rft_end_on"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Contract Period From"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "format": "dd/MM/yyyy",
      "disabled": !_vm.creating && !_vm.editable
    },
    model: {
      value: _vm.form.contract_period_from,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contract_period_from", $$v);
      },
      expression: "form.contract_period_from"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Contract Period To"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "format": "dd/MM/yyyy",
      "disabled": !_vm.creating && !_vm.editable
    },
    model: {
      value: _vm.form.contract_period_to,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contract_period_to", $$v);
      },
      expression: "form.contract_period_to"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Maximum Items in Bundle",
      "prop": "max_items_in_bundle"
    }
  }, [_c('el-input-number', {
    attrs: {
      "placeholder": "Maximum Items in Bundle",
      "min": 0,
      "disabled": !_vm.creating && !_vm.editable
    },
    model: {
      value: _vm.form.max_items_in_bundle,
      callback: function ($$v) {
        _vm.$set(_vm.form, "max_items_in_bundle", $$v);
      },
      expression: "form.max_items_in_bundle"
    }
  }), _vm._v(" Enter 0 for unlimited ")], 1), _c('el-form-item', {
    attrs: {
      "label": "Public Page",
      "prop": "is_public"
    }
  }, [_c('el-switch', {
    attrs: {
      "disabled": !_vm.creating && !_vm.editable
    },
    model: {
      value: _vm.form.is_public,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_public", $$v);
      },
      expression: "form.is_public"
    }
  }), _vm.form.is_public && _vm.publicUrl ? _c('a', {
    staticClass: "ml-2",
    attrs: {
      "href": _vm.publicUrl,
      "target": "_BLANK"
    }
  }, [_vm._v(" " + _vm._s(_vm.publicUrl) + " ")]) : _vm._e(), _vm.form.is_public && _vm.publicUrl ? _c('el-tooltip', {
    staticClass: "item ml-2",
    attrs: {
      "content": "This url will work after this RFT is published",
      "placement": "top"
    }
  }, [_c('i', {
    staticClass: "el-icon-info"
  })]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }