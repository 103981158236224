var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoadingRftProduct,
      expression: "isLoadingRftProduct"
    }],
    ref: "rftDraftProductForm",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "label-width": "200px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "name"
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.requested_product_name) + " "), _c('el-tooltip', {
    attrs: {
      "content": _vm.CombinationUtil.headermap_product.tip.requested_product_name,
      "placement": "top"
    }
  }, [_c('i', {
    staticClass: "el-tooltip el-icon-question"
  })])], 1), _vm.editableRftProduct ? _c('el-autocomplete', {
    attrs: {
      "clearable": "",
      "fetch-suggestions": _vm.querySearchAsync,
      "placeholder": "Please type the keyword",
      "readonly": !_vm.editableRftProduct,
      "trigger-on-focus": _vm.editableRftProduct
    },
    on: {
      "select": _vm.handleSelect
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "name"
        }, [_vm._v(_vm._s(item.name))]), item.organisations ? _c('small', {
          staticClass: "uuid"
        }, [_vm._v("(" + _vm._s(_vm.showAlias(item)) + ") ")]) : _vm._e(), _c('small', {
          staticClass: "uuid"
        }, [_vm._v(_vm._s(item.uuid))])];
      }
    }], null, false, 2957376230),
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, [_c('i', {
    staticClass: "el-icon-edit el-input__icon",
    attrs: {
      "slot": "suffix"
    },
    slot: "suffix"
  })]) : _vm._e(), this.form.product_id && this.form.product_id.length && _vm.editableRftProduct ? _c('div', {
    staticClass: "px-0 mt-4"
  }, [_c('el-card', {
    staticClass: "box-card",
    attrs: {
      "shadow": "never"
    }
  }, _vm._l(this.form.product_id, function (p) {
    return _c('div', {
      key: ("list_p" + (p.id)),
      staticClass: "text item"
    }, [_vm._v("   "), _c('el-tooltip', {
      staticClass: "box-item",
      attrs: {
        "effect": "light",
        "content": "Remove item",
        "placement": "right"
      }
    }, [true ? _c('a', {
      staticClass: "text-danger",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.removeItemHandler(p);
        }
      }
    }, [_c('mdicon', {
      attrs: {
        "name": "delete-outline",
        "size": 20
      }
    })], 1) : _vm._e()]), _vm._v("   "), p.alias ? _c('small', [_vm._v("(" + _vm._s(p.alias) + ")")]) : _vm._e(), _c('small', [_vm._v("(" + _vm._s(p.uuid ? p.uuid : p.product_uuid) + ") | " + _vm._s(p.name))]), _vm._v("   "), true ? _c('a', {
      staticClass: "text-success",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.handleAliasInfo(p);
        }
      }
    }, [_vm._v(" Alias ")]) : _vm._e()], 1);
  }), 0)], 1) : _vm._e(), !_vm.editableRftProduct ? _c('div', {
    staticClass: "px-0 mt-0"
  }, [_c('el-card', {
    staticClass: "box-card",
    attrs: {
      "shadow": "never"
    }
  }, _vm._l(this.form.product_id, function (p) {
    return _c('div', {
      key: ("list_p" + (p.id)),
      staticClass: "text item border-bottom"
    }, [p.alias ? _c('small', [_vm._v("(" + _vm._s(p.alias) + ")")]) : _vm._e(), _c('small', [_vm._v("(" + _vm._s(p.uuid ? p.uuid : p.product_uuid) + ") | " + _vm._s(p.name))])]);
  }), 0)], 1) : _vm._e()], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_product.note,
      "prop": "note"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Note",
      "readonly": !_vm.editableRftProduct
    },
    model: {
      value: _vm.form.note,
      callback: function ($$v) {
        _vm.$set(_vm.form, "note", $$v);
      },
      expression: "form.note"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "unit_quantity"
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.unit_quantity) + " "), _c('el-tooltip', {
    attrs: {
      "content": _vm.CombinationUtil.headermap_product.tip.unit_quantity,
      "placement": "top"
    }
  }, [_c('i', {
    staticClass: "el-tooltip el-icon-question"
  })])], 1), _c('el-input-number', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": _vm.CombinationUtil.headermap_product.unit_quantity,
      "min:1": "",
      "disabled": !_vm.editableRftProduct
    },
    model: {
      value: _vm.form.unit_quantity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "unit_quantity", $$v);
      },
      expression: "form.unit_quantity"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "min_shelf_life_months"
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.min_shelf_life_months) + " "), _c('el-tooltip', {
    attrs: {
      "content": _vm.CombinationUtil.headermap_product.tip.min_shelf_life_months,
      "placement": "top"
    }
  }, [_c('i', {
    staticClass: "el-tooltip el-icon-question"
  })])], 1), _c('el-input-number', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": _vm.CombinationUtil.headermap_product.min_shelf_life_months,
      "min:1": "",
      "disabled": !_vm.editableRftProduct
    },
    model: {
      value: _vm.form.min_shelf_life_months,
      callback: function ($$v) {
        _vm.$set(_vm.form, "min_shelf_life_months", $$v);
      },
      expression: "form.min_shelf_life_months"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_product.current_brand,
      "prop": "current_brand"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": _vm.CombinationUtil.headermap_product.current_brand,
      "readonly": !_vm.editableRftProduct
    },
    model: {
      value: _vm.form.current_brand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "current_brand", $$v);
      },
      expression: "form.current_brand"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_product.current_supplier,
      "prop": "current_supplier"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": _vm.CombinationUtil.headermap_product.current_supplier,
      "readonly": !_vm.editableRftProduct
    },
    model: {
      value: _vm.form.current_supplier,
      callback: function ($$v) {
        _vm.$set(_vm.form, "current_supplier", $$v);
      },
      expression: "form.current_supplier"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_product.current_unit_measure,
      "prop": "current_unit_measure"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": _vm.CombinationUtil.headermap_product.current_unit_measure,
      "readonly": !_vm.editableRftProduct
    },
    model: {
      value: _vm.form.current_unit_measure,
      callback: function ($$v) {
        _vm.$set(_vm.form, "current_unit_measure", $$v);
      },
      expression: "form.current_unit_measure"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_product.current_unit_price,
      "prop": "current_unit_price"
    }
  }, [_c('el-input-number', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": _vm.CombinationUtil.headermap_product.current_unit_price,
      "disabled": !_vm.editableRftProduct
    },
    model: {
      value: _vm.form.current_unit_price,
      callback: function ($$v) {
        _vm.$set(_vm.form, "current_unit_price", $$v);
      },
      expression: "form.current_unit_price"
    }
  })], 1), _vm._t("default")], 2), _vm.latestcurrentprice.length ? _c('div', {
    attrs: {
      "id": "div-latestcurrentprice"
    }
  }, [_c('h4', {
    staticClass: "text-center"
  }, [_vm._v("Last awarded price(s):")]), _c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.latestcurrentprice,
      "id": "table-latestcurrentprice"
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "rft_alias",
      "label": _vm.CombinationUtil.headermap_bid.rft_alias,
      "width": "130"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_product.rft_start_on,
      "width": "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm.CombinationUtil.formatCol("rft_start_on", scope.row.rft_start_on, "product")) + " ")];
      }
    }], null, false, 829373159)
  }), _c('el-table-column', {
    attrs: {
      "min-width": 140,
      "prop": "trade_product_name",
      "label": _vm.CombinationUtil.headermap_bid.trade_product_name
    }
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_product.unit_quantity,
      "align": _vm.CombinationUtil.getColAlign('unit_quantity'),
      "width": "95"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm.CombinationUtil.formatCol("unit_quantity", scope.row.unit_quantity, "product")) + " ")];
      }
    }], null, false, 3902049927)
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_bid.single_price,
      "align": _vm.CombinationUtil.getColAlign('single_price'),
      "width": "95"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm.CombinationUtil.formatCol("single_price", scope.row.single_price, "bid")) + " ")];
      }
    }], null, false, 4222549059)
  }), _vm.latestcurrentprice.length > 1 ? _c('el-table-column', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          attrs: {
            "type": "info",
            "size": "small",
            "width": "80"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.useLatestPrice(scope.row);
            }
          }
        }, [_c('span', [_vm._v("Use")])])];
      }
    }], null, false, 370085826)
  }) : _vm._e()], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "productspeccategory-editor-modal"
  }, [_c('OrgProductEditorModal', {
    ref: "orgProductEditorModal"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }