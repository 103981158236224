var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-tabs', {
    attrs: {
      "tab-position": "top",
      "type": "border-card"
    },
    on: {
      "tab-click": _vm.handleChangeTab
    },
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_vm._l(_vm.tabs, function (tab) {
    return [_vm.accessible(tab) ? _c('el-tab-pane', {
      key: tab,
      attrs: {
        "name": tab
      }
    }, [_c('span', {
      attrs: {
        "slot": "label"
      },
      slot: "label"
    }, [_c('mdicon', {
      attrs: {
        "name": _vm.iconName(tab),
        "size": 18
      }
    }), _vm._v(" " + _vm._s(_vm.tabLabel(tab)) + " ")], 1), _c('RftList', {
      directives: [{
        name: "permission",
        rawName: "v-permission",
        value: 'rft-list',
        expression: "'rft-list'"
      }],
      ref: "Tab",
      refInFor: true
    })], 1) : _vm._e()];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }