var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-button', {
    staticClass: "mb-4",
    attrs: {
      "type": "plain",
      "icon": "el-icon-arrow-left",
      "link": ""
    },
    on: {
      "click": function ($event) {
        return _vm.goBack();
      }
    }
  }, [_vm._v(" Go Back ")]), _c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('el-steps', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    attrs: {
      "active": _vm.activeStep,
      "finish-status": "success",
      "align-center": ""
    }
  }, [_c('el-step', {
    attrs: {
      "status": _vm.stepIsReady('overview')
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.handleChangeTab('overview');
      }
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('mdicon', {
    attrs: {
      "name": "receipt",
      "size": "18"
    }
  }), _vm._v(" Key Details ")], 1)], 2), !_vm.creating ? _c('el-step', {
    attrs: {
      "status": _vm.stepIsReady('products')
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.handleChangeTab('products');
      }
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('mdicon', {
    attrs: {
      "name": "pill",
      "size": "18"
    }
  }), _vm._v(" Products ")], 1)], 2) : _vm._e(), !_vm.creating ? _c('el-step', {
    attrs: {
      "status": _vm.stepIsReady('specifications')
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.handleChangeTab('specifications');
      }
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('mdicon', {
    attrs: {
      "name": "tag",
      "size": "18"
    }
  }), _vm._v(" Product Specifications ")], 1)], 2) : _vm._e(), !_vm.creating ? _c('el-step', {
    attrs: {
      "status": _vm.stepIsReady('suppliers')
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.handleChangeTab('suppliers');
      }
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('mdicon', {
    attrs: {
      "name": "account-multiple-plus",
      "size": "18"
    }
  }), _vm._v(" Suppliers ")], 1)], 2) : _vm._e()], 1)], 1)]), _c('div', {
    staticClass: "text-center mb-4"
  }, [_vm.isDraft && _vm.tabs.length === _vm.readyList.length ? _c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" This draft is ready to be published. "), _vm.user && _vm.user.role_name != 'Manager' ? _c('span', [_vm._v(" Please contact your manager. ")]) : _vm._e()]) : _vm._e(), _c('el-popover', {
    staticClass: "mx-3",
    attrs: {
      "placement": "bottom",
      "width": "230",
      "trigger": "click"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('el-tag', {
    staticClass: "mr-2 mb-2 w-100"
  }, [_c('mdicon', {
    attrs: {
      "name": "receipt",
      "size": "14"
    }
  }), _vm._v(" " + _vm._s(_vm.info.rft_name) + " ")], 1)], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 col-sm-12 pr-1"
  }, [_c('el-tag', {
    staticClass: "mr-2 mb-2 w-100"
  }, [_c('mdicon', {
    attrs: {
      "name": "receipt",
      "size": "14"
    }
  }), _vm._v(" " + _vm._s(_vm.info.uuid) + " ")], 1)], 1), _c('div', {
    staticClass: "col-md-6 col-sm-12 pl-1"
  }, [_c('el-tag', {
    staticClass: "mr-2 mb-2 w-100",
    attrs: {
      "type": "success"
    }
  }, [_vm._v(" " + _vm._s(_vm.editingObject.rft_type_code) + " ")])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('el-tag', {
    staticClass: "mr-2 mb-2 w-100",
    attrs: {
      "type": "info"
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "clock",
      "size": "14"
    }
  }), _vm._v(" " + _vm._s(_vm.info.rft_start_on) + " to " + _vm._s(_vm.info.rft_end_on) + " ")], 1)], 1), _c('div', {
    staticClass: "col-md-12"
  }, [_c('el-tag', {
    staticClass: "mr-2 mb-2 w-100",
    attrs: {
      "type": "info"
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "account-box",
      "size": "14"
    }
  }), _vm._v(" " + _vm._s(_vm.info.contact_person) + " ")], 1), _c('a', {
    staticClass: "btn btn-outline-success btn-sm btn-block",
    attrs: {
      "target": "_blank",
      "href": ("tel:" + (_vm.info.contact_phone))
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "phone",
      "size": "14"
    }
  }), _vm._v(_vm._s(_vm.info.contact_phone))], 1), _c('a', {
    staticClass: "btn btn-outline-success btn-sm btn-block",
    attrs: {
      "target": "_blank",
      "href": ("mailto:" + (_vm.info.contact_email))
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "email",
      "size": "14"
    }
  }), _vm._v(_vm._s(_vm.info.contact_email))], 1)], 1)]), !_vm.creating ? _c('el-button', {
    attrs: {
      "slot": "reference",
      "type": "info"
    },
    slot: "reference"
  }, [_c('mdicon', {
    attrs: {
      "name": "information-outline",
      "size": 14
    }
  }), _vm._v(" Info ")], 1) : _vm._e()], 1), _vm.isDraft && _vm.isReadyForPublish ? _c('el-button', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: ['rft-edit-status'],
      expression: "['rft-edit-status']"
    }],
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.publish();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "publish",
      "size": 14
    }
  }), _vm._v(" Publish ")], 1) : _vm._e()], 1), _c('div', {
    staticClass: "card-group"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c(_vm.draftComponent, {
    ref: "dynamicComponent",
    tag: "component"
  }), _c('div', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'rft-edit',
      expression: "'rft-edit'"
    }],
    staticClass: "text-center"
  }, [_vm.currentTab !== 'products' && _vm.isDraft ? _c('div', {
    staticClass: "rft-form"
  }, [!_vm.editable && !_vm.creating ? _c('div', [_vm.currentTab != 'suppliers' ? _c('div', [_c('b-button', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'rft-edit',
      expression: "'rft-edit'"
    }],
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        return _vm.enableEdit();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "pencil-lock-outline",
      "size": 18
    }
  }), _vm._v(" Edit")], 1), _c('b-button', {
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v(" Next step ")])], 1) : _c('div', [_vm.getSupplierEditState || _vm.editable ? _c('CompleteButton', {
    attrs: {
      "creating": _vm.creating
    },
    on: {
      "cancelCreating": _vm.cancelCreating,
      "cancel": _vm.cancel,
      "onSubmit": _vm.onSubmit,
      "reset": _vm.reset
    }
  }) : _c('div', [_c('b-button', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'rft-edit',
      expression: "'rft-edit'"
    }],
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        return _vm.enableEdit();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "pencil-lock-outline",
      "size": 18
    }
  }), _vm._v(" Edit")], 1), _c('b-button', {
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v(" Next step ")])], 1)], 1)]) : _c('div', [_vm.getSupplierEditState || _vm.editable ? _c('CompleteButton', {
    attrs: {
      "creating": _vm.creating
    },
    on: {
      "cancelCreating": _vm.cancelCreating,
      "cancel": _vm.cancel,
      "onSubmit": _vm.onSubmit,
      "reset": _vm.reset
    }
  }) : _vm._e()], 1)]) : _c('div', {
    staticClass: "rft-table"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v("Next step")])], 1)])], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }