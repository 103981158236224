import { render, staticRenderFns } from "./RftEditDraftProductList.vue?vue&type=template&id=132ffec6&scoped=true&"
import script from "./RftEditDraftProductList.vue?vue&type=script&lang=ts&"
export * from "./RftEditDraftProductList.vue?vue&type=script&lang=ts&"
import style0 from "./RftEditDraftProductList.vue?vue&type=style&index=0&id=132ffec6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "132ffec6",
  null
  
)

export default component.exports