var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    attrs: {
      "label-width": "120px"
    }
  }, [_c('h6', [_vm._v("Suppliers")]), _c('p', [_vm._v(" In this section, the buyer selects which suppliers will be invited to tender. Once you have completed this section, please click the save button."), _c('br'), _vm._v(" Once you have completed all the sections and you are satisfied with the tender, clicking the Publish button will finalise the tender and email all the suppliers invited to participate after the start date."), _c('br'), _vm._v(" You can edit all sections at anytime prior to clicking the Publish button. When you click the Publish button the tender can no longer be edited. ")]), _c('div', {
    staticClass: "row mb-2"
  }, [_c('div', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'rft-edit',
      expression: "'rft-edit'"
    }],
    staticClass: "col-12"
  }, [_vm.editable || _vm.getSupplierEditState ? [_c('el-button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.selectAll.apply(null, arguments);
      }
    }
  }, [_c('mdicon', {
    staticClass: "text-success",
    attrs: {
      "name": "checkbox-multiple-marked-circle",
      "size": 20
    }
  }), _vm._v(" Select all ")], 1), _c('el-button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.removeAll.apply(null, arguments);
      }
    }
  }, [_c('mdicon', {
    staticClass: "text-danger",
    attrs: {
      "name": "close-circle-outline",
      "size": 20
    }
  }), _vm._v(" Remove all ")], 1)] : _vm._e(), _c('el-button', {
    attrs: {
      "type": "outline",
      "icon": "el-icon-user"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'organisation.invite'
        });
      }
    }
  }, [_vm._v(" " + _vm._s("Invite Supplier") + " ")])], 2)]), _c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, _vm._l(_vm.supplierList, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('el-divider'), _c('div', {
      staticClass: "d-flex flex-row",
      attrs: {
        "role": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.toggleCheckbox(item.id);
        }
      }
    }, [_c('div', {
      staticClass: "pr-2"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.suppliers,
        expression: "suppliers"
      }],
      attrs: {
        "type": "checkbox",
        "disabled": !_vm.getSupplierEditState
      },
      domProps: {
        "value": item.id,
        "checked": Array.isArray(_vm.suppliers) ? _vm._i(_vm.suppliers, item.id) > -1 : _vm.suppliers
      },
      on: {
        "change": function ($event) {
          var $$a = _vm.suppliers,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = item.id,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.suppliers = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.suppliers = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.suppliers = $$c;
          }
        }
      }
    })]), _c('div', [_c('h6', [_vm._v(_vm._s(item.name))]), _c('span', {
      staticClass: "m-b-15 d-block"
    }, [_vm._v(" ABN: " + _vm._s(item.abn)), _c('br'), _c('mdicon', {
      staticClass: "text-success",
      attrs: {
        "name": "map-marker",
        "size": 18
      }
    }), _vm._v(" " + _vm._s(item.street + ", " + item.suburb + " " + item.state.code.toUpperCase() + " " + item.postcode) + " "), item.website ? _c('span', [_c('br'), _c('mdicon', {
      staticClass: "text-success",
      attrs: {
        "name": "web",
        "size": 16
      }
    }), _vm._v(" " + _vm._s(item.website))], 1) : _vm._e()], 1)])])], 1);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }