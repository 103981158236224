var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h6', [_vm._v("Product Specifications")]), _vm._m(0), _c('el-divider'), _vm.editable ? _c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('span', [_vm._v(" Apply preselected group: "), _c('el-select', {
    staticClass: "white-out",
    attrs: {
      "placeholder": "Select"
    },
    on: {
      "change": _vm.applyGroup
    },
    model: {
      value: _vm.groupSelect,
      callback: function ($$v) {
        _vm.groupSelect = $$v;
      },
      expression: "groupSelect"
    }
  }, _vm._l(_vm.availableGroups, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.name,
        "value": item.id
      }
    });
  }), 1)], 1)])]) : _vm._e(), _c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    ref: "orgProductSpecGroupForm",
    attrs: {
      "model": _vm.form,
      "label-width": "120px"
    }
  }, [_c('el-collapse', {
    model: {
      value: _vm.collapActiveNames,
      callback: function ($$v) {
        _vm.collapActiveNames = $$v;
      },
      expression: "collapActiveNames"
    }
  }, [_vm._l(_vm.availableSpecs, function (categories, index) {
    return [_vm.editable || _vm.countByCategory[categories.id] ? _c('el-collapse-item', {
      key: index,
      attrs: {
        "name": categories.id
      }
    }, [_c('template', {
      slot: "title"
    }, [_vm.countByCategory[categories.id] ? _c('el-badge', {
      staticClass: "categorycount",
      attrs: {
        "value": _vm.countByCategory[categories.id]
      }
    }, [_c('strong', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(categories.category))])]) : _c('strong', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(categories.category))])], 1), _c('div', {
      staticClass: "table-responsive"
    }, [_c('table', {
      staticClass: "table email-table no-wrap table-hover v-middle"
    }, [_c('tbody', [_vm._l(categories.specifications, function (spec, i) {
      return [_vm.editable || _vm.selections.includes.indexOf(spec.id) !== -1 ? _c('tr', {
        key: i
      }, [_c('td', [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.selections.includes,
          expression: "selections.includes"
        }],
        attrs: {
          "disabled": _vm.editable ? false : 'disabled',
          "type": "checkbox"
        },
        domProps: {
          "value": spec.id,
          "checked": Array.isArray(_vm.selections.includes) ? _vm._i(_vm.selections.includes, spec.id) > -1 : _vm.selections.includes
        },
        on: {
          "change": [function ($event) {
            var $$a = _vm.selections.includes,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;
            if (Array.isArray($$a)) {
              var $$v = spec.id,
                $$i = _vm._i($$a, $$v);
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.selections, "includes", $$a.concat([$$v]));
              } else {
                $$i > -1 && _vm.$set(_vm.selections, "includes", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.$set(_vm.selections, "includes", $$c);
            }
          }, function (event) {
            return _vm.handleCheck(event, spec.id);
          }]
        }
      }), _c('label', {
        staticClass: "pl-2",
        attrs: {
          "role": "button"
        },
        on: {
          "click": function ($event) {
            return _vm.toggleCheckboxIncludes(spec.id);
          }
        }
      }, [_vm._v("Include")])]), _c('td', [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.selections.mandatories,
          expression: "selections.mandatories"
        }],
        attrs: {
          "disabled": _vm.editable ? false : 'disabled',
          "type": "checkbox"
        },
        domProps: {
          "value": spec.id,
          "checked": Array.isArray(_vm.selections.mandatories) ? _vm._i(_vm.selections.mandatories, spec.id) > -1 : _vm.selections.mandatories
        },
        on: {
          "change": [function ($event) {
            var $$a = _vm.selections.mandatories,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;
            if (Array.isArray($$a)) {
              var $$v = spec.id,
                $$i = _vm._i($$a, $$v);
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.selections, "mandatories", $$a.concat([$$v]));
              } else {
                $$i > -1 && _vm.$set(_vm.selections, "mandatories", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.$set(_vm.selections, "mandatories", $$c);
            }
          }, function (event) {
            return _vm.handleCheck(event, spec.id, 'mandatories');
          }]
        }
      }), _c('label', {
        staticClass: "pl-2",
        attrs: {
          "role": "button"
        },
        on: {
          "click": function ($event) {
            return _vm.toggleCheckboxMandatories(spec.id);
          }
        }
      }, [_vm._v("Mandatory")])]), _c('td', [_vm._v(" " + _vm._s(spec.name)), _c('br'), _c('span', {
        staticClass: "small"
      }, [_vm._v(_vm._s(spec.description))])])]) : _vm._e()];
    })], 2)])])], 2) : _vm._e()];
  })], 2), _c('div', {
    staticClass: "mt-4"
  }, [_vm._t("default")], 2)], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" In this section, the buyer selects the tendered product specification which will be requested from the suppliers."), _c('br'), _vm._v(" Once you have completed this section, please click the save button and then click the next step button at the bottom of this page."), _c('br'), _vm._v("You can edit all sections at anytime prior to clicking the Publish button. ")]);
}]

export { render, staticRenderFns }