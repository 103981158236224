var render = function () {
  var _this$rft;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "flex align-items-center mb-5",
    staticStyle: {
      "gap": "16px"
    }
  }, [_c('el-button', {
    staticClass: "mb-4",
    attrs: {
      "type": "plain",
      "icon": "el-icon-arrow-left",
      "link": ""
    },
    on: {
      "click": function ($event) {
        return _vm.goBack();
      }
    }
  }, [_vm._v("Go Back ")]), _c('h5', [_vm._v(_vm._s((_this$rft = this.rft) === null || _this$rft === void 0 ? void 0 : _this$rft.rft_name))])], 1), _c('el-tabs', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    attrs: {
      "tab-position": "top",
      "type": "border-card"
    },
    on: {
      "tab-click": _vm.handleChangeTab
    },
    model: {
      value: _vm.activePane,
      callback: function ($$v) {
        _vm.activePane = $$v;
      },
      expression: "activePane"
    }
  }, [_c('el-tab-pane', {
    attrs: {
      "name": "overview",
      "lazy": ""
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('mdicon', {
    attrs: {
      "name": "receipt",
      "size": 18
    }
  }), _vm._v(" Key Details ")], 1), _c('RftEditActiveOverview')], 1), _vm.isTabShow('product') ? _c('el-tab-pane', {
    attrs: {
      "name": "product",
      "lazy": ""
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('mdicon', {
    attrs: {
      "name": "pill",
      "size": 18
    }
  }), _vm._v(" Products & Bids ")], 1), _c('RftEditActiveProductList')], 1) : _vm._e(), _vm.isTabShow('bundle') ? _c('el-tab-pane', {
    attrs: {
      "name": "bundle",
      "lazy": ""
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('mdicon', {
    attrs: {
      "name": "package-variant-closed",
      "size": 18
    }
  }), _vm._v(" Products Bundles ")], 1), _c('RftEditActiveBundle')], 1) : _vm._e(), _vm.isTabShow('clinicalEvaluation') ? _c('el-tab-pane', {
    attrs: {
      "name": "clinical_evaluation",
      "lazy": ""
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('mdicon', {
    attrs: {
      "name": "table",
      "size": 18
    }
  }), _vm._v(" Clinical Evaluation ")], 1), _c('RftClinicalEvaluation')], 1) : _vm._e(), _vm.isTabShow('rank') ? _c('el-tab-pane', {
    attrs: {
      "name": "rank",
      "lazy": ""
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('mdicon', {
    attrs: {
      "name": "sort",
      "size": 18
    }
  }), _vm._v(" Bid Evaluation ")], 1), _c('RftEditActiveRank')], 1) : _vm._e(), _vm.isTabShow('awarded') ? _c('el-tab-pane', {
    attrs: {
      "name": "awarded",
      "lazy": ""
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('mdicon', {
    attrs: {
      "name": "seal-variant",
      "size": 18
    }
  }), _vm._v(" Awarded Products ")], 1), _c('RftEditClosedAwarded')], 1) : _vm._e(), _vm.isTabShow('invitation') ? _c('el-tab-pane', {
    attrs: {
      "name": "invitation",
      "lazy": ""
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('mdicon', {
    attrs: {
      "name": "account-multiple",
      "size": "18"
    }
  }), _vm._v(" Suppliers ")], 1), _c('RftSuppliers')], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }