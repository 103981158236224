var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isStatus('draft') ? _c('RftEditDraft') : _vm.isStatus('active') ? _c('RftEditActive', {
    attrs: {
      "rftType": _vm.getType()
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }